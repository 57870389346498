import { Link } from '@remix-run/react'

export default function AddToHomescreen() {
	if (typeof window !== 'undefined') {
		if (window.matchMedia('(display-mode: standalone)').matches) {
			return null
		}
	}
	return (
		<Link to={'/install-app'}>
			<div className="fixed inset-x-0 bottom-0 pb-20 sm:hidden sm:pb-5">
				<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
					<div
						className="rounded-lg p-2 shadow-lg sm:p-3"
						style={{ background: '#001837' }}
					>
						<div className="flex flex-wrap items-center justify-between">
							<div className="flex w-0 flex-1 items-center">
								<span className="bg-brand text-brandcontrast dark:bg-darkmodebrand dark:text-darkmodebrandcontrast flex rounded-lg bg-opacity-30 p-2">
									<svg
										className="h-7 w-7 fill-current text-[#D5B066]"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 50 50"
										enableBackground="new 0 0 50 50"
									>
										<path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" />
										<path d="M24 7h2v21h-2z" />
										<path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
									</svg>
								</span>
								<p className="ms-3 text-xs font-medium text-iagtGold dark:text-white">
									<span className="inline">
										Add this app to your home screen for faster access and
										improved experience.
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Link>
	)
}
